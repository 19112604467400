import React, { Fragment,Component } from 'react'
import BasePage from './components/BasePage'
import TopBar from './components/TopBar'
import SideBar from './components/SideBar'
import { getSomeData } from '../controllers/api'
import NotFound from './extras/NotFound'




class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
      isLoading: true,
      error:null,
    };
  }

  async componentDidMount() {
    try {
      const jsonData = await getSomeData(); // Replace with your data-fetching logic
      this.setState({ data: jsonData, isLoading:false });
      
    } catch (error) {
        this.setState({ error, isLoading: false });
      }
  }


  render() {

      
    const { data,isLoading,error } = this.state;


  if (isLoading) {
    return (
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 col-12">
              <div className="mb-4">
                <img
                  src="assets/images/svg/under_maintenance.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="text-center">
                <h2 className="mb-3 text-muted">Loading</h2>
                <p className="text-dark-emphasis fs-15 mb-1">
                  Please be patient.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    // return <p>Error: {error.message}</p>;

    return <NotFound />;
  }
  return (
    <div>
        
        
        <BasePage title="ड्यासबोर्ड"
    
    body={
        <Fragment>
           
        <div className="wrapper">
            <TopBar title="ड्यासबोर्ड"/>
            <SideBar/>
            <div className="content-page">
                <div className="content">

                    <div className="container-fluid">

                    <div className="row">
						<div className="col-xl-4">
							<div className="card overflow-hidden border-top-0">
								<div className="progress progress-sm rounded-0 bg-light" role="progressbar" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100">
									<div className="progress-bar bg-primary" style={{width: "90%"}}></div>
								</div>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="">
											<p className="text-muted fw-semibold fs-16 mb-1">बडापत्र संख्या</p>
											{/* <p className="text-muted mb-4">
												<span className="badge bg-success-subtle text-success">
													<i className="bi bi-graph-up-arrow me-1"></i> 1.33%
												</span>
												vs last month
											</p> */}
										</div>
										<div className="avatar-sm mb-4">
											<div className="avatar-title bg-primary-subtle text-primary fs-24 rounded">
												<i className="bi bi-receipt"></i>
											</div>
										</div>
									</div>
									<div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-end">
										<h3 className="mb-0 d-flex">{data.count.badapatra}</h3>
										<div className="d-flex align-items-end h-100">
											<div id="daily-orders" data-colors="#007aff"></div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-4">
							<div className="card overflow-hidden border-top-0">
								<div className="progress progress-sm rounded-0 bg-light" role="progressbar"
									aria-valuenow="88" aria-valuemin="0" aria-valuemax="100">
									<div className="progress-bar bg-dark" style={{width: "40%"}}></div>
								</div>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="">
											<p className="text-muted fw-semibold fs-16 mb-1">सक्रिय प्रयोगकर्ता</p>
											{/* <p className="text-muted mb-4"><span className="badge bg-danger-subtle text-danger"><i className="bi bi-graph-down-arrow me-1"></i> 5.27%</span> vs last
												month
											</p> */}
										</div>
										<div className="avatar-sm mb-4">
											<div className="avatar-title bg-dark-subtle text-dark fs-24 rounded">
												<i className="bi bi-people"></i>
											</div>
										</div>
									</div>
									<div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-end">
										<h3 className="mb-0 d-flex">{data.count.users}</h3>
										<div className="d-flex align-items-end h-100">
											<div id="new-leads-chart" data-colors="#404040"></div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xl-4">
							<div className="card overflow-hidden border-top-0">
								<div className="progress progress-sm rounded-0 bg-light" role="progressbar" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100">
									<div className="progress-bar bg-danger" style={{width: "60%"}}></div>
								</div>
								<div className="card-body">
									<div className="d-flex align-items-center justify-content-between">
										<div className="">
											<p className="text-muted fw-semibold fs-16 mb-1">राजपत्र संख्या</p>
											{/* <p className="text-muted mb-4">
												<span className="badge bg-success-subtle text-success"><i
														className="bi bi-graph-up-arrow me-1"></i> 11.8%</span>
												vs last month
											</p> */}
										</div>
										<div className="avatar-sm mb-4">
											<div className="avatar-title bg-danger-subtle text-danger fs-24 rounded">
												<i className="bi bi-clipboard-data"></i>
											</div>
										</div>
									</div>
									<div className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-end">
										<h3 className="mb-0 d-flex">{data.count.rajpatra}</h3>
										<div className="d-flex align-items-end h-100">
											<div id="booked-revenue-chart" data-colors="#bb3939"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
                        
                    </div> 

                </div>

                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <script>document.write(new Date().getFullYear())</script> © Softech Foundation
                            </div>
                        </div>
                    </div>
                </footer>

            </div>
        </div>
        </Fragment>
    }
    
    />
    </div>
  )
}}

export default HomePage