import React, { Fragment } from 'react'
import TopBar from '../components/TopBar'
import SideBar from '../components/SideBar'
import BasePage from '../components/BasePage'
const BivagTathaKaryaXettra = () => {
  return (
    <div>
        
        
    <BasePage title="बिभाग तथा कार्यक्षेत्र"

body={
    <Fragment>
       
    <div class="wrapper">
        <TopBar title="बिभाग तथा कार्यक्षेत्र"/>
        <SideBar/>
        <div class="content-page">
            <div class="content">

                <div class="container-fluid">

                    
                    
                </div> 

            </div>

            <footer class="footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 text-center">
                            <script>document.write(new Date().getFullYear())</script> © Softech Foundation
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    </div>
    </Fragment>
}

/>
</div>
  )
}

export default BivagTathaKaryaXettra