import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../views/HomePage';
import LoginPage from '../views/auth/LoginPage';
import NotFound from '../views/extras/NotFound';
import BadaPatra from '../views/pages/BadaPatra';
import Workers from '../views/pages/Workers';
import BivagTathaKaryaXettra from '../views/pages/BivagTathaKaryaXettra';
import Emergency from '../views/pages/Emergency';
import HelloMayor from '../views/pages/HelloMayor';
import JanaGunaso from '../views/pages/JanaGunaso';

import SiteInfo from '../views/pages/SiteInfo';
import SlideShow from '../views/pages/SlideShow';
import Tourist from '../views/pages/Tourist';
import Rajpatra from '../views/pages/Rajpatra';
import Notice from '../views/pages/Notice';




const Routing = () => {
    return (
        <Router>
            <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path='/dashboard' element={<HomePage />} />
                <Route path='/badapatra' element={<BadaPatra />} />
                <Route path='/workers' element={<Workers />} />
                <Route path='/departments' element={<BivagTathaKaryaXettra />} />
                <Route path='/emergency' element={<Emergency />} />
                <Route path='/hellomayor' element={<HelloMayor />} />
                <Route path='/public-complaints' element={<JanaGunaso />} />
                <Route path='/rajpatra' element={<Rajpatra />} />
                <Route path='/notice' element={<Notice />} />

                <Route path='/site-info' element={<SiteInfo />} />
                <Route path='/slide-show' element={<SlideShow />} />
                <Route path='/tourist' element={<Tourist />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    )
}

export default Routing