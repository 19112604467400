import React from 'react'
import { Link } from 'react-router-dom'

const TopBar = (props) => {
  return (
 
        
        <div className="navbar-custom">
    <div className="topbar container-fluid">
        <div className="d-flex align-items-center gap-1">

            <div className="logo-topbar">
                <Link to="index.html" className="logo-light">
                    <span className="logo-lg">
                        <img src="favicon.png" alt="logo"/>
                    </span>
                    <span className="logo-sm">
                        <img src="favicon.png" alt="small logo"/>
                    </span>
                </Link>

                <Link to="index.html" className="logo-dark">
                    <span className="logo-lg">
                    <img src="favicon.png" alt="logo"/>
                    </span>
                    <span className="logo-sm">
                    <img src="favicon.png" alt="small logo"/>
                    </span>
                </Link>
            </div>

            <button className="button-toggle-menu">
                <i className="mdi mdi-menu"></i>
            </button>

            <h4 className="page-title d-none d-sm-block">{props.title}</h4>
        </div>

        <ul className="topbar-menu d-flex align-items-center gap-3">
            <li className="dropdown d-lg-none">
                <Link className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                    <i className="mdi mdi-magnify fs-2"></i>
                </Link>
                <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                    <form className="p-3">
                        <input type="search" className="form-control" placeholder="Search ..." aria-label="Recipient's username"/>
                    </form>
                </div>
            </li>

            <li>
            <img src="assets/images/flags/np.png" alt="user" className="me-0 me-sm-1" height="30"/>
                    <span className="align-middle d-none d-lg-inline-block">Nepali</span>

                   

            </li>

            


            <li className="dropdown">
                <Link className="nav-link dropdown-toggle arrow-none nav-user" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                    <span className="account-user-avatar">
                        <img src="assets/images/users/avatar-1.jpg" alt="user" width="32" className="rounded-circle"/>
                    </span>
                    <span className="d-lg-block d-none">
                        <h5 className="my-0 fw-normal">किरण ढकाल<i className="ri-arrow-down-s-line fs-22 d-none d-sm-inline-block align-middle"></i></h5>
                    </span>
                </Link>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                    <div className=" dropdown-header noti-title">
                        <h6 className="text-overflow m-0">Welcome !</h6>
                    </div>

                    <Link to="pages-profile.html" className="dropdown-item">
                        <i className="ri-account-pin-circle-line fs-16 align-middle me-1 "></i>
                        <span>My Account</span>
                    </Link>

                  
                  

                    <Link to="auth-logout.html" className="dropdown-item">
                        <i className="ri-logout-circle-r-line align-middle me-1"></i>
                        <span>Logout</span>
                    </Link>
                </div>
            </li>
        </ul>
    </div>
 
</div>
 
  )
}

export default TopBar