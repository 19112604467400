import React, { Fragment,Component } from 'react'
import TopBar from '../components/TopBar'
import SideBar from '../components/SideBar'
import BasePage from '../components/BasePage'
import { Link } from 'react-router-dom'
import { getSomeData } from '../../controllers/api'




class SiteInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
      error: null,
    };
  }

  componentDidMount() {
   
    getSomeData()
      .then((data) => {
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  }

  render() {
    const { data, isLoading, error } = this.state;

    if (isLoading) {
      return  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-lg-5 col-md-6 col-12">
                  <div class="mb-4">
                      <img src="assets/images/svg/under_maintenance.png" alt="" class="img-fluid"/>
                  </div>
                  <div class="text-center">
                      <h2 class="mb-3 text-muted">Loading</h2>
                      <p class="text-dark-emphasis fs-15 mb-1">Please be patient.</p>
                  </div>

              </div> 
          </div>
      </div>
  </div>;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    const value = data['info'][0];

    return (
        <div>
                
                
                <BasePage title="साइट जानकारी"
            
            body={
                <Fragment>
                   
                <div class="wrapper">
                    <TopBar title="साइट जानकारी"/>
                    <SideBar/>
                    <div class="content-page">
                        <div class="content">
        
                            <div class="container-fluid">
        
                            <div class="table-responsive-sm">
                                                <table class="table table-bordered table-centered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>नाम</th>
                                                            <th>ठेगाना</th>
                                                            <th>नारा</th>
                                                            <th>वर्णन</th>
                                                            <th>द्वारा संचालित</th>
                                                            <th>आइकन</th>
                                                            <th>द्वारा संचालित आइकन</th>
                                                            <th>स्प्ल्याश छवि</th>
                                                            <th class="text-center">कार्यहरू</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td >
                                                              
                                                               {value['name']}
                                                            </td>
                                                            <td>  {value['address']}</td>
                                                            <td>  {value['slogan']}</td>
                                                            <td>  {value['description']}</td>
                                                            <td>  {value['powered_by']}</td>
                                                            <td >  <img   src={`data:image/png;base64, ${value['icon']}`} alt="icon" class="avatar-lg"/></td>
                                                            <td  >  <img src={`data:image/png;base64, ${value['powered_by_icon']}`} alt="powered by" class="avatar-lg"/></td>
                                                            
                                                            <td >  <img alt='splash' width={90} height={90} src={`data:image/png;base64, ${value['splash_bg']}`} class="avatar-lg " /></td>
                                                            
                                                            <td class="text-center">
                                                                <Link data-bs-toggle="modal" data-bs-target="#standard-modal" class="text-reset fs-16 px-1"> <i class="ri-edit-line text-success"></i></Link>
                                                            </td>
                                                        </tr>
                                                       
                                                    </tbody>
                                                </table>
                                            </div>
                                
                            </div> 
        
                        </div>
                        <div id="standard-modal" class="modal fade" tabindex="-1" aria-labelledby="standard-modalLabel" style={{display: 'none'}} aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h4 class="modal-title" id="standard-modalLabel">साइट जानकारी अपडेट</h4>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                       <form>
                                               


                                                <div class="mb-3">
                                                    <label for="example-palaceholder" class="form-label">नाम</label>
                                                    <input type="text" id="example-palaceholder" class="form-control" value={value['name']}/>
                                                </div>

                                                <div class="mb-3">
                                                    <label for="example-palaceholder" class="form-label">ठेगाना</label>
                                                    <input type="text" id="example-palaceholder" class="form-control" value={value['address']}/>
                                                </div>

                                                <div class="mb-3">
                                                    <label for="example-palaceholder" class="form-label">नारा</label>
                                                    <input type="text" id="example-palaceholder" class="form-control" value={value['slogan']}/>
                                                </div>


                                                <div class="mb-3">
                                                    <label for="example-textarea" class="form-label">वर्णन</label>
                                                    <textarea class="form-control" id="example-textarea" rows="5" value={value['description']}></textarea>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="example-palaceholder" class="form-label">द्वारा संचालित</label>
                                                    <input type="text" id="example-palaceholder" class="form-control" value={value['powered_by']}/>
                                                </div>


                                                
                                                <div class="mb-3">
                                                    <label for="icon" class="form-label">आइकन</label>
                                                    <input type="file"  accept="image/*" id="icon" class="form-control"/>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="poweredByIcon" class="form-label">द्वारा संचालित आइकन</label>
                                                    <input type="file"  accept="image/*" id="poweredByIcon" class="form-control"/>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="splashImage" class="form-label">स्प्ल्याश छवि</label>
                                                    <input type="file"  accept="image/*" id="splashImage" class="form-control"/>
                                                </div>
                                       
                                            

        

                                            </form>
                                                        </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-primary">Save changes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                       
        
                    </div>
                </div>
                </Fragment>
            }
            
            />
            
            </div>
          );
  }
}

export default SiteInfo;
