import React, { Fragment, Component } from "react";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import BasePage from "../components/BasePage";
import { getSomeData } from "../../controllers/api";
import NotFound from "../extras/NotFound";
import DataTable from "datatables.net-dt/js/dataTables.dataTables.js";
import "datatables.net-dt/css/jquery.dataTables.css";
import "jquery/dist/jquery.min.js";

class BadaPatra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      data: null,
      isLoading: true,
      error: null,
      jsonData: [],
      formCount: [],
      updatingData: [],
    };
  }

  async componentDidMount() {
    try {
      const data = await getSomeData();

      const jsonData = [];
      const formCount = [];

      data.attributes[1].category[0].subcategory.forEach((item) => {
        item.alldata.forEach((items) => {
          jsonData.push(items);
        });
      });

      const dataKeys = JSON.parse(data.keys[1].data);

      for (let i = 0; i < Object.keys(dataKeys).length; i++) {
        formCount.push(1);
      }

      this.setState({ data, jsonData, isLoading: false, formCount });

      // this.setState({})
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  onClickUpdate = (bool, data) => {
    this.setState({ isUpdating: bool });

    if (bool === true) {
      this.setState({ updatingData: data });

      const formCount = Object.values(JSON.parse(data.data)).map(
        (value) => value.length
      );

      this.setState({ formCount });

      console.log(formCount);
    } else {
      this.setState({ updatingData: [] });
    }
  };

  initializeDataTable = () => {
    new DataTable("#badapatra");
    this.setState({ isLoading: false });
  };

  handleIncreaseCount = (index) => {
    const { formCount } = this.state;
    const updatedFormCounts = [...formCount];
    updatedFormCounts[index] += 1;
    this.setState({ formCount: updatedFormCounts });
  };

  handleDecreaseCount = (index) => {
    const { formCount } = this.state;
    const updatedFormCounts = [...formCount];
    if (updatedFormCounts[index] !== 1) {
      updatedFormCounts[index] -= 1;
    }
    this.setState({ formCount: updatedFormCounts });
  };

  render() {
    const {
      data,
      isLoading,
      error,
      jsonData,
      formCount,
      isUpdating,
      updatingData,
    } = this.state;
    // const updateData = JSON.parse(updatingData.data);

    if (isLoading) {
      return (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6 col-12">
                <div className="mb-4">
                  <img
                    src="assets/images/svg/under_maintenance.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="text-center">
                  <h2 className="mb-3 text-muted">Loading</h2>
                  <p className="text-dark-emphasis fs-15 mb-1">
                    Please be patient.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      // return <p>Error: {error.message}</p>;

      return <NotFound />;
    }

    return (
      <div onLoad={this.initializeDataTable}>
        <BasePage
          title="बडापत्र"
          body={
            <Fragment>
              <div className="wrapper">
                <TopBar title="बडापत्र" />
                <SideBar />
                <div className="content-page">
                  <div className="content">
                    <div className="container-fluid">
                      <button
                        onClick={() => this.onClickUpdate(false)}
                        type="button"
                        className="btn btn-md  btn-success m-1 p-1 "
                        data-bs-toggle="modal"
                        data-bs-target="#standard-modal"
                      >
                        Add New
                      </button>
                      <div className="card-body">
                        <table id="badapatra" className="display ">
                          <thead>
                            <tr>
                              {Object.entries(JSON.parse(data["keys"][1].data))
                              .map(([key, value]) => (
                                <th key={key}>{value}</th>
                              ))}
                              <th>उप श्रेणी</th>
                              <th>कार्यहरू</th>
                            </tr>
                          </thead>

                          <tbody>
                            {jsonData.map((dataItem, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                {Object.entries(JSON.parse(dataItem["data"]))
                                  .slice(1)
                                  .map(([key, value]) => (
                                    <td key={key}>{value}</td>
                                  ))}

                                <td>
                                  {
                                    data["single"]["sub_category"].find(
                                      (item) =>
                                        item.id === dataItem["sub_category_id"]
                                    ).name
                                  }
                                </td>
                                <td>
                                  <div className="d-flex flex-wrap gap-2">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      data-bs-toggle="modal"
                                      data-bs-target="#danger-alert-modal"
                                    >
                                      <i className="ri-delete-bin-line "></i>
                                    </button>

                                    <button
                                      onClick={() =>
                                        this.onClickUpdate(true, dataItem)
                                      }
                                      type="button"
                                      className="btn btn-success"
                                      data-bs-toggle="modal"
                                      data-bs-target="#standard-modal"
                                    >
                                      <i className="ri-pencil-line"></i>{" "}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="danger-alert-modal"
                className="modal fade"
                tabIndex="-1"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog modal-sm">
                  <div className="modal-content modal-filled bg-danger">
                    <div className="modal-body p-4">
                      <div className="text-center">
                        <i className="ri-delete-bin-2-line h1"></i>
                        <h4 className="mt-2">Are you sure!</h4>
                        <p className="mt-3">
                          Do you really want to delete this item?
                        </p>
                        <button
                          type="button"
                          className="btn btn-light my-2"
                          data-bs-dismiss="modal"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="standard-modal"
                className="modal fade"
                tabIndex="-1"
                aria-labelledby="standard-modalLabel"
                style={{ display: "none" }}
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="standard-modalLabel">
                        साइट जानकारी {isUpdating ? "अपडेट" : "थप्नुहोस्"}
                      </h4>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="inputState" className="form-label">
                            उप श्रेणी
                          </label>
                          <select id="inputState" className="form-select">
                            {data["single"]["sub_category"]
                              .filter((item) => item.category_id === 2)
                              .map((item, index) => (
                                // {item.id === updatingData.sub_category_id ? "true" : "false"}
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {Object.entries(JSON.parse(data["keys"][1].data))
                        .map(([key, value], indexValue) => (
                          <div key={key}>
                            <label
                              htmlFor="example-placeholder"
                              className="form-label"
                            >
                              {value}
                            </label>
                            {[...Array(formCount[indexValue])].map(
                              (_, countForm) => (
                                <div
                                  className="mb-3"
                                  key={`${key}_${countForm}`}
                                >
                                  <div className="d-flex" key={indexValue}>
                                    <button
                                      onClick={() =>
                                        this.handleDecreaseCount(indexValue)
                                      }
                                      type="button"
                                      className="btn btn-danger"
                                    >
                                      <i className="ri-subtract-line"></i>
                                    </button>

                                    {updatingData && updatingData.data ? (
                                      <input
                                        type="text"
                                        id="example-placeholder"
                                        className="form-control"
                                        placeholder={
                                          JSON.parse(updatingData["data"])[key][
                                            countForm
                                          ]
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        id="example-placeholder"
                                        className="form-control"
                                      />
                                    )}

                                    <button
                                      onClick={() =>
                                        this.handleIncreaseCount(indexValue)
                                      }
                                      type="button"
                                      className="btn btn-success"
                                    >
                                      <i className="ri-add-line"></i>
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          }
        />
      </div>
    );
  }
}

export default BadaPatra;
