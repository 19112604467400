import React,{Fragment} from 'react'
import BasePage from '../components/BasePage'

const LoginPage = () => {
  return (
    <div>
        <BasePage title="Login "

        body={
            <Fragment>
                 <div class="account-pages p-sm-5  position-relative">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xxl-4 col-lg-9">
                    <div class="card overflow-hidden">
                        <div class="row g-0">
                            <div class="col-lg-12">
                                <div class="d-flex flex-column h-100">
                                    <div class="auth-brand p-4 text-center">
                                       
                                        <a href="index.html" class="logo-dark">
                                            <img src="favicon.png" alt="dark logo" height="60"/>
                                        </a>
                                    </div>
                                    <div class="p-4 my-auto text-center">
                                        <h4 class="fs-20">Sign In</h4>
                                        <p class="text-muted mb-4">Enter your email address and password to <br/> access
                                            account.
                                        </p>

                                        <form action="/" class="text-start">
                                            <div class="mb-3">
                                                <label for="emailaddress" class="form-label">Email address</label>
                                                <input class="form-control" type="email" id="emailaddress" required=""
                                                    placeholder="Enter your email"/>
                                            </div>
                                            <div class="mb-3">
                                                {/* <a href="auth-forgotpw.html" class="text-muted float-end"><small>Forgot
                                                        your
                                                        password?</small></a> */}
                                                <label for="password" class="form-label">Password</label>
                                                <input class="form-control" type="password" required="" id="password"
                                                    placeholder="Enter your password"/>
                                            </div>
                                            <div class="mb-3">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input"
                                                        id="checkbox-signin"/>
                                                    <label class="form-check-label" for="checkbox-signin">Remember
                                                        me</label>
                                                </div>
                                            </div>
                                            <div class="mb-0 text-start">
                                                <button class="btn btn-soft-primary w-100" type="submit"><i
                                                        class="ri-login-circle-fill me-1"></i> <span class="fw-bold">Log
                                                        In</span> </button>
                                            </div>

                                            
                                        </form>
                                    </div>
                                </div>
                            </div> 
                          
                        </div>
                    </div>
                </div>
            </div>
         
        
        </div>
    </div>

    <footer class="footer footer-alt fw-medium">
        <span class="text-dark">
            <script>document.write(new Date().getFullYear())</script> © Softech Foundation 
        </span>
    </footer>
                </Fragment>
        }
        
    />
    </div>
  )
}

export default LoginPage