import Routing from './routes/Routing';


function App() {
  return (
 
    <Routing />



  );
}

export default App;
