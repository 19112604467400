import React, { Fragment, Component } from "react";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import BasePage from "../components/BasePage";
import { getSomeData } from "../../controllers/api";
import NotFound from "../extras/NotFound";

import DataTable from 'datatables.net-dt/js/dataTables.dataTables.js';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'jquery/dist/jquery.min.js';

class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isUpdating: false,
          data: null,
          isLoading: true,
          error: null,
          jsonData: [],
      
          updatingData: [],
        };
      }
    
      async componentDidMount() {
      
        this.initializeDataTable();
        try {
          const data = await getSomeData(); 
          
        
          const jsonData = [];
  
    
    
          jsonData.push(data.emergency);
    console.log(jsonData);
    
          this.setState({  jsonData, isLoading: false });
        } catch (error) {
          this.setState({ error, isLoading: false });
        }
      }
    
      onClickUpdate = (bool, data) => {
        this.setState({ isUpdating: bool });
    
        if (bool === true) {
          this.setState({ updatingData: data });
    
          const formCount = Object.values(JSON.parse(data.data)).map((value) => value.length);
    
          this.setState({ formCount });
        
    
          console.log(formCount);
        }else{
          
    
          this.setState({updatingData:[] });
        }
    
      };
    
    
      initializeDataTable = () => {
    
       new DataTable('#workers');
       this.setState({});
      };
    
   
      
      render() {
     
        const { isLoading, error ,jsonData,isUpdating,updatingData} = this.state;
        // const updateData = JSON.parse(updatingData.data);
        const nepaliTableHeadings =  [
          
            'नाम',
            "फोन",
           

          ];
          
    
        if (isLoading) {
          return (
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-6 col-12">
                    <div className="mb-4">
                      <img
                        src="assets/images/svg/under_maintenance.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-center">
                      <h2 className="mb-3 text-muted">Loading</h2>
                      <p className="text-dark-emphasis fs-15 mb-1">
                        Please be patient.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
    
        if (error) {
          // return <p>Error: {error.message}</p>;
    
          return <NotFound/>
        }
    
    
    
    
    
        return (
          <div  onLoad={this.initializeDataTable}>
         
        
    <BasePage title="स्लाइड शो"

body={
    <Fragment>
       
    <div class="wrapper">
        <TopBar title="सूचना"/>
        <SideBar/>
        <div class="content-page">
            <div class="content">

                <div class="container-fluid">

                <button
                          onClick={() => this.onClickUpdate(false)}
                        type="button" className="btn btn-md  btn-success m-1 p-1 "  data-bs-toggle="modal" data-bs-target="#standard-modal" >Add New</button>
                          <div className="card-body">
                           
                          <table id="workers" className="display ">
  <thead>
    <tr>
        <th> क्रमिक नम्बर</th>
    { nepaliTableHeadings.map((heading, index) => (
        <th key={index}>{heading}</th>
       ))}
        <th>कार्यहरू</th>
    </tr>
  </thead>
  <tbody>
   
    {jsonData.map((dataItem) => (
        dataItem.map((user,index) => (
            <tr key={user.id}>
       
       <td>{index+1}</td>
         <td>{user.title}</td>
       
         <td>{user.number}</td>
        <td>
           <div className="d-flex flex-wrap gap-2">
             <button
               type="button"
               className="btn btn-danger"
               data-bs-toggle="modal"
               data-bs-target="#danger-alert-modal"
             >
               <i className="ri-delete-bin-line "></i>
             </button>
   
             <button
               onClick={() => this.onClickUpdate(true)}
               type="button"
               className="btn btn-success"
               data-bs-toggle="modal"
               data-bs-target="#standard-modal"
             >
               <i className="ri-pencil-line"></i>{" "}
             </button>
           </div>
         </td>
       </tr>
     ))
    ))}
       
   
  </tbody>
</table>

</div>
                    
                </div> 

            </div>

            <footer class="footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 text-center">
                            <script>document.write(new Date().getFullYear())</script> © Softech Foundation
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    </div>
    </Fragment>
}

/>
</div>
  )
}
}
export default Notice