import React, { Fragment } from 'react'
import TopBar from '../components/TopBar'
import SideBar from '../components/SideBar'
import BasePage from '../components/BasePage'
import { Link } from 'react-router-dom'

const JanaGunaso = () => {
  return (
<div>
        
        
        <BasePage title="जन गुनासो"
    
    body={
        <Fragment>
           
        <div class="wrapper">
            <TopBar title="जन गुनासो"/>
            <SideBar/>
            <div class="content-page">
                <div class="content">

                <div class="container-fluid">



<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="timeline timeline-left">
                    <article class="timeline-item alt">
                        <div class="text-start">
                            <div class="time-show first">
                                <Link to="#" class="btn btn-primary w-lg">Today</Link>
                            </div>
                        </div>
                    </article>
                    <article class="timeline-item">
                        <div class="timeline-desk">
                            <div class="panel">
                                <div class="timeline-box">
                                    <span class="arrow"></span>
                                    <span class="timeline-icon"><i class="mdi mdi-record-circle-outline"></i></span>
                                    <h4 class="fs-16 fw-semibold ">1 hour ago</h4>
                                    <p class="timeline-date text-muted"><small>08:25 am</small></p>
                                    <p>Dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? </p>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="timeline-item ">
                        <div class="timeline-desk">
                            <div class="panel">
                                <div class="timeline-box">
                                    <span class="arrow"></span>
                                    <span class="timeline-icon bg-success"><i class="mdi mdi-record-circle-outline"></i></span>
                                    <h4 class="fs-16 fw-semibold text-success">2 hours ago</h4>
                                    <p class="timeline-date text-muted"><small>08:25 am</small></p>
                                    <p>consectetur adipisicing elit. Iusto, optio, dolorum <Link to="#">John deon</Link> provident rerum aut hic quasi placeat iure tempora laudantium </p>

                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="timeline-item">
                        <div class="timeline-desk">
                            <div class="panel">
                                <div class="timeline-box">
                                    <span class="arrow"></span>
                                    <span class="timeline-icon bg-primary"><i class="mdi mdi-record-circle-outline"></i></span>
                                    <h4 class="fs-16 fw-semibold text-primary">10 hours ago</h4>
                                    <p class="timeline-date text-muted"><small>08:25 am</small></p>
                                    <p>3 new photo Uploaded on facebook fan page</p>
                                    <div class="album">
                                        <Link to="#">
                                            <img alt="" src="assets/images/small/small-4.jpg"/>
                                        </Link>
                                        <Link to="#">
                                            <img alt="" src="assets/images/small/small-5.jpg"/>
                                        </Link>
                                        <Link to="#">
                                            <img alt="" src="assets/images/small/small-6.jpg"/>
                                        </Link>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="timeline-item">
                        <div class="timeline-desk">
                            <div class="panel">
                                <div class="timeline-box">
                                    <span class="arrow"></span>
                                    <span class="timeline-icon bg-purple"><i class="mdi mdi-record-circle-outline"></i></span>
                                    <h4 class="fs-16 fw-semibold text-purple">14 hours ago</h4>
                                    <p class="timeline-date text-muted"><small>08:25 am</small></p>
                                    <p>Outdoor visit at California State Route 85 with John Boltana &amp; Harry Piterson regarding to setup a new show room.</p>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article class="timeline-item">
                        <div class="timeline-desk">
                            <div class="panel">
                                <div class="timeline-box">
                                    <span class="arrow"></span>
                                    <span class="timeline-icon"><i class="mdi mdi-record-circle-outline"></i></span>
                                    <h4 class="fs-16 fw-semibold text-muted">19 hours ago</h4>
                                    <p class="timeline-date text-muted"><small>08:25 am</small></p>
                                    <p>Jonatha Smith added new milestone <span><Link to="#">Pathek</Link></span> Lorem ipsum dolor sit amet consiquest dio</p>
                                </div>
                            </div>
                        </div>
                    </article>

                </div>
            </div>
        </div>
    </div>
</div>

</div>

                </div>

             

            </div>
        </div>
        </Fragment>
    }
    
    />
    </div>
  )
}

export default JanaGunaso