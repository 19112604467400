import React from 'react'

const NotFound = () => {
  return (
    <div className='wrapper'>

                <div className="content">

                    <div className="container-fluid mt-6">

                        <div className="d-flex flex-column align-items-center justify-content-center h-100 my-auto">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <img src="assets/images/svg/404.svg" alt="" className="img-fluid h-100 w-100"/>
                                        <h1 className="my-3 fs-48">404</h1>
                                        <h3 className="fs-22">Page not found</h3>
                                        <p className="text-muted mb-3 fs-16"> It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us.</p>
    
                                        <a className="btn btn-soft-primary mt-3 w-100" href="/dashboard"><i className="ri-home-4-line me-1"></i> Back to Home</a>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        
                    </div> 

                </div> 

                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <script>document.write(new Date().getFullYear())</script>2023 © Softech Foundation 
                            </div>
                        </div>
                    </div>
                </footer>

            </div>

  )
}

export default NotFound