
import React from 'react'
import { Helmet } from 'react-helmet'
const BasePage = (props) => {
 
  
  return (
 


  
           
      <>
      <Helmet>
          <title>{ props.title }</title>
    
  

        </Helmet>
       {props.body}
       <footer className="footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <script>document.write(new Date().getFullYear())</script> © Softech Foundation
                                    </div>
                                </div>
                            </div>
                        </footer>

   <Helmet>


  <script src="assets/js/app.min.js"></script>

   </Helmet>
      </>
   
    
     
  )
}

export default BasePage