import React, { Fragment, Component } from "react";
import TopBar from "../components/TopBar";
import SideBar from "../components/SideBar";
import BasePage from "../components/BasePage";
import { getSomeData } from "../../controllers/api";
import NotFound from "../extras/NotFound";

import DataTable from 'datatables.net-dt/js/dataTables.dataTables.js';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'jquery/dist/jquery.min.js';



class Workers extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isUpdating: false,
          data: null,
          isLoading: true,
          error: null,
          jsonData: [],
      
          updatingData: [],
        };
      }
    
      async componentDidMount() {
      
        this.initializeDataTable();
        try {
          const data = await getSomeData(); 
          
        
          const jsonData = [];
  
    
    
          jsonData.push(data.users);
    console.log(jsonData);
    
          this.setState({ data, jsonData, isLoading: false });
        } catch (error) {
          this.setState({ error, isLoading: false });
        }
      }
    
      onClickUpdate = (bool, data) => {
        this.setState({ isUpdating: bool });
    
        if (bool === true) {
          this.setState({ updatingData: data });
    
          const formCount = Object.values(JSON.parse(data.data)).map((value) => value.length);
    
          this.setState({ formCount });
        
    
          console.log(formCount);
        }else{
          
    
          this.setState({updatingData:[] });
        }
    
      };
    
    
      initializeDataTable = () => {
    
       new DataTable('#workers');
       this.setState({});
      };
    
   
      
      render() {
     
        const {  isLoading, error ,jsonData,isUpdating,updatingData} = this.state;
        // const updateData = JSON.parse(updatingData.data);
        const nepaliTableHeadings = [
         
            "नाम",
            "इमेल",
            "पत्ता",
            "फोन",
            "प्रतिनिधि",
            "वडा",
            "वडा नम्बर",
            "पद",
            "विवरण",
            "प्रोफाइल तस्विर",
            "कार्यहरू"

          ];
          
    
        if (isLoading) {
          return (
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-6 col-12">
                    <div className="mb-4">
                      <img
                        src="assets/images/svg/under_maintenance.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="text-center">
                      <h2 className="mb-3 text-muted">Loading</h2>
                      <p className="text-dark-emphasis fs-15 mb-1">
                        Please be patient.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
    
        if (error) {
          // return <p>Error: {error.message}</p>;
    
          return <NotFound/>
        }
    
    
    
    
    
        return (
          <div  onLoad={this.initializeDataTable}>
           
    
            <BasePage
              title="प्रतिनिधि कर्मचारी"
              body={
                <Fragment>
                  <div className="wrapper">
                    <TopBar title="प्रतिनिधि कर्मचारी" />
                    <SideBar />
                    <div className="content-page">
                      <div className="content">
                        <div className="container-fluid">
                        <button
                          onClick={() => this.onClickUpdate(false)}
                        type="button" className="btn btn-md  btn-success m-1 p-1 "  data-bs-toggle="modal" data-bs-target="#standard-modal" >Add New</button>
                          <div className="card-body">
                           
                          <table id="workers" className="display ">
  <thead>
    
    <tr>
    <th> क्रमिक नम्बर</th>
       { nepaliTableHeadings.map((heading, index) => (
        <th key={index}>{heading}</th>
       ))}
      {/* <th>क्रमिक नम्बर</th>
      <th>नाम</th>
      <th>इमेल</th>
      <th>पत्ता</th>
      <th>फोन</th>
      <th>प्रतिनिधि</th>
      <th>वडा</th>
      <th>वडा नम्बर</th>
      <th>पद</th>
      <th>विवरण</th>
      <th>प्रोफाइल तस्विर</th>
      <th>कार्यहरू</th> */}
    </tr>
  </thead>
  <tbody>
   
    {jsonData.map((dataItem) => (
        dataItem.map((user,index) => (
            <tr key={user.id}>
       
       <td>{index+1}</td>
         <td>{user.name}</td>
         <td>{user.email}</td>
   
         <td>{user.userdetail.address}</td>
         
         <td>{user.userdetail.phone}</td>
         <td>{user.userdetail.isPratinidhi}</td>
         <td>{user.userdetail.isWada}</td>
         <td>{user.userdetail.wadaNumber}</td>
         <td>{user.userdetail.position}</td>
         <td>{user.userdetail.description}</td>
         <td >  <img   src={`data:image/png;base64, ${user.userdetail.profile_picture}`} alt="icon" className="avatar-lg"/></td>
         <td>
           <div className="d-flex flex-wrap gap-2">
             <button
               type="button"
               className="btn btn-danger"
               data-bs-toggle="modal"
               data-bs-target="#danger-alert-modal"
             >
               <i className="ri-delete-bin-line "></i>
             </button>
   
             <button
               onClick={() => this.onClickUpdate(true)}
               type="button"
               className="btn btn-success"
               data-bs-toggle="modal"
               data-bs-target="#standard-modal"
             >
               <i className="ri-pencil-line"></i>{" "}
             </button>
           </div>
         </td>
       </tr>
     ))
    ))}
       
   
  </tbody>
</table>

{
        //    <p >{}{JSON.stringify(jsonData)}</p>

          
        }
        
        
        
        


       
        
  {/* {Object.entries(JSON.parse(dataItem["data"]))
                                    .slice(1)
                                    .map(([key, value]) => (
                                    <p>value</p>
                                    ))}  */}


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div
              id="danger-alert-modal"
              className="modal fade"
              tabIndex="-1"
              style={{ display: "none" }}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content modal-filled bg-danger">
                  <div className="modal-body p-4">
                    <div className="text-center">
                      <i className="ri-delete-bin-2-line h1"></i>
                      <h4 className="mt-2">Are you sure!</h4>
                      <p className="mt-3">Do you really want to delete this item?</p>
                      <button
                        type="button"
                        className="btn btn-light my-2"
                        data-bs-dismiss="modal"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
              <div id="standard-modal" className="modal fade" tabIndex="-1" aria-labelledby="standard-modalLabel" style={{display: 'none'}} aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title" id="standard-modalLabel">साइट जानकारी {isUpdating? 'अपडेट':'थप्नुहोस्'}</h4>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                           <form>
                                                          
                                                           {nepaliTableHeadings.map((value, indexValue) => (
                                      
                                    
    
                            
                                      <div key={indexValue}>
                                         <label htmlFor="example-placeholder" className="form-label">{value}</label>
                                   
                                        <div className="mb-3" key={`${indexValue}`}>
                                         
                                        
                                            
                                          
    
                                            
    
    
    {updatingData && updatingData.data ? (
    
    
    <input
                                            
    type="text"
    id="example-placeholder"
    className="form-control"
    
    placeholder="l"
    />
    
    ) : (
    <input
                                            
                                            type="text"
                                            id="example-placeholder"
                                            className="form-control"
                                            
                                     
                                            />
    )}
    
    
                                          
                                          
                                          {/* {updatingData && updatingData.data ? (
    
    
                                              // Object.entries(updatingData).map(([key, value], indexValue) => (
                                              //   <p key={key}>{}{value}</p>
                                              // )
                                              <p> {JSON.parse(updatingData['data'])[key][0]}</p>
    
    ) : (
      <p>Updating data is undefined or missing</p>
    )} */}
    
                                         
                                        </div>
    
                                      
                                    </div>
                                  
                              
                                    ))}
    
                                                   
    
                                                   
    
            
    
                                                </form>
                                                            </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                            <button type="button" className="btn btn-primary">Save changes</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                </Fragment>
              }
            />
           
          </div>
        );
      }
    }

export default Workers