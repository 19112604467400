import React from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
  return (
    <div className="leftside-menu">

    <Link to="/dashboard" className="logo logo-light">
        <span className="logo-lg text-white fs-4">
        <img src="favicon.png" alt="logo"/> डिजिटल पालिका
        </span>
        <span className="logo-sm">
        <img src="favicon.png" alt=" small logo"/>
        </span>
    </Link>

    <Link to="/dashboard" className="logo logo-dark">
        <span className="logo-lg fs-4">
        <img src="favicon.png" alt="logo"/> डिजिटल पालिका
        </span>
        <span className="logo-sm">
        <img src="favicon.png" alt=" small logo"/>
        </span>
    </Link>

    <div data-simplebar>
        <ul className="side-nav">
            <li className="side-nav-title">मुख्य</li>

            <li className="side-nav-item">
                <Link to="/dashboard" className="side-nav-link">
                    <i className="ri-dashboard-2-line"></i>
                    <span> ड्यासबोर्ड </span>
                    <span className="badge bg-success float-end">0</span>
                </Link>
            </li>

            <li className="side-nav-title">मोबाइल एप</li>

            <li className="side-nav-item">
                <Link to="/site-info" className="side-nav-link">
                    <i className="ri-pages-line"></i>
                    <span> साइट जानकारी</span>

                </Link>
            </li>

            <li className="side-nav-item">
                <Link to="/slide-show" className="side-nav-link">
                    <i className="ri-slideshow-line"></i>
                    <span>स्लाइड शो</span>
                </Link>
            </li>

            <li className="side-nav-item">
                <Link data-bs-toggle="collapse" to="#syanchar" aria-expanded="false" aria-controls="syanchar" className="side-nav-link">
                    <i className="ri-feedback-line"></i>
                    <span>संचार</span>
                    <span className="menu-arrow"></span>

                </Link>
                <div className="collapse" id="syanchar">
                    <ul className="side-nav-second-level">
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/hellomayor">नमस्ते मेयर</Link>
                        </li>
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/public-complaints">जन गुनासो</Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="side-nav-item">
                <Link data-bs-toggle="collapse" to="#jankari" aria-expanded="false" aria-controls="jankari" className="side-nav-link">
                    <i className="ri-information-line"></i>
                    <span>जानकरी</span>
                    <span className="menu-arrow"></span>

                </Link>
                <div className="collapse" id="jankari">
                    <ul className="side-nav-second-level">
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/badapatra">बडापत्र</Link>
                        </li>
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/workers">प्रतिनिधि कर्मचारी</Link>
                        </li>
                    </ul>
                </div>
            </li>


            <li className="side-nav-item">
                <Link to="/departments" className="side-nav-link">
                    <i className="ri-building-line"></i>
                    <span> बिभाग तथा कार्यक्षेत्र </span>
                    <span className=" float-end">  <i className="ri-arrow-right-s-line "></i></span>
                </Link>
            </li>
           

            <li className="side-nav-item">
                <Link data-bs-toggle="collapse" to="#sewa" aria-expanded="false" aria-controls="sewa" className="side-nav-link">
                    <i className="ri-service-line"></i>
                    <span>सेवाहरू</span>
                    <span className="menu-arrow"></span>

                </Link>
                <div className="collapse" id="sewa">
                    <ul className="side-nav-second-level">
                        {/* <li className="side-nav-item">
                            <Link className="side-nav-link" to="/parichaya">परिचय</Link>
                        </li>
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/emergency">अकस्मिक</Link>
                        </li>
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/tourist">पर्यटक स्थल</Link>
                        </li> */}
                    </ul>
                </div>
            </li>

            <li className="side-nav-item">
                <Link data-bs-toggle="collapse" to="#anyajankari" aria-expanded="false" aria-controls="anyajankari" className="side-nav-link">
                    <i className="ri-spam-line"></i>
                    <span>अन्य जानकारी</span>
                    <span className="menu-arrow"></span>

                </Link>
                <div className="collapse" id="anyajankari">
                    <ul className="side-nav-second-level">
                   
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/emergency">अकस्मिक</Link>
                        </li>
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/tourist">पर्यटक स्थल</Link>
                        </li>
                    </ul>
                </div>
            </li>
            <li className="side-nav-item">
                <Link data-bs-toggle="collapse" to="#extra" aria-expanded="false" aria-controls="anyajankari" className="side-nav-link">
                    <i className="ri-spam-line"></i>
                    <span>थप</span>
                    <span className="menu-arrow"></span>

                </Link>
                <div className="collapse" id="extra">
                    <ul className="side-nav-second-level">
                   
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/rajpatra">राजपत्र</Link>
                        </li>
                        <li className="side-nav-item">
                            <Link className="side-nav-link" to="/notice">सूचना</Link>
                        </li>
                    </ul>
                </div>
            </li>

            


        </ul>
    </div>
    
</div>
  )
}

export default SideBar